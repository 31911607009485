<template>
  <Page>
    <VendorBody ref="body" />
  </Page>
</template>

<script>
import VendorBody from '../bodies/Vendor.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  name: 'VendorPage',
  data() {
    return {}
  },
  components: { VendorBody }
}
</script>
